:root {
    /* Define color variables */
    --google-maps-color: #ea4335;
    --primary-color: #fcbd1a;
}

/* Align span elements vertically to the middle */
span {
    vertical-align: middle;
}

/* Set a smaller bottom margin for h2 elements */
h2 {
    margin-bottom: 0.3em;
}

/* Style the button element */
button {
    border-radius: 10px;
    margin: 1em;
    padding: 0.6em;
    background-color: var(--primary-color);
}

/* Style the container element as a flex container */
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Style the stationInfo element */
.stationInfo {
    margin-bottom: 0.8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Style the stationName link element */
.stationName {
    text-decoration: none;
    color: black;
}

/* Position the location element at the bottom */
.location {
    position: absolute;
    bottom: 1em;
}

/* Set the color of google maps icon */
.material-icons.google-maps-color {
    color: var(--google-maps-color);
}

/* Set the color for dark theme material icons */
.material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
}

/* Set the inactive color for dark theme material icons */
.material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}

/* Set the color for light theme material icons */
.material-icons.md-light {
    color: rgba(255, 255, 255, 1);
}

/* Set the inactive color for light theme material icons */
.material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}

/* Rules for sizing the icon */
.material-icons.md-18 {
    font-size: 18px;
}
.material-icons.md-24 {
    font-size: 24px;
}
.material-icons.md-36 {
    font-size: 36px;
}
.material-icons.md-48 {
    font-size: 48px;
}
